const variables = {
  preplixPrimary: "#D10724",
  sectionPrimary: "#ECF8FF",
  preplixCards: "#2D83C50D",
  background: "#DEEDF7",
};

export const styles = {
  root: {
    backgroundColor: variables.background,
    padding: "20px",
    height: "auto",
  },
  button: {
    borderRadius: "15px",
    padding: "15px 35px",
    boxShadow: "none",
  },
  add_user_button: {
    borderRadius: "24px 0px 24px 0px",
    textTransform: "capitalize",
  },
  table_section: {
    width: "100%",
    overflow: "hidden",
    maxWidth: "calc(100vw - 120px)",
    marginTop: 2,
  },
  table_container: {
    maxHeight: "calc(100vh - 315px)",
    overflow: "auto",
  },
  submit_button: {
    background: `${variables.preplixPrimary}`,
    "&:hover": {
      background: `${variables.preplixPrimary}`,
    },
  },
  reset_button: {
    background: `${variables.sectionPrimary}`,
    marginRight: "10px",
    color: "#000000",
    "&:hover": {
      background: `${variables.sectionPrimary}`,
    },
  },
  pagination: {
    "& .Mui-selected": {
      backgroundColor: `${variables.preplixPrimary} !important`,
      color: "white",
    },
  },
  filter_item: {
    paddingX: 2,
    borderRight: `1px solid ${variables.preplixPrimary}`,
    "&:last-child": {
      borderRight: "none",
    },
    "@media (max-width: 1021px)": {
      borderRight: "none",
    },
  },
  modalContent: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "12px",
    minWidth: "950px",
    maxHeight: "calc(100vh - 50px)",
    overflow: "hidden",
    overflowY: "scroll",
  },
  btnPrimary: {
    background: `${variables.preplixPrimary}`,
    marginRight: "2rem",
    marginTop: "10px",
    padding: "10px 12px",
    minWidth: "173px",
    borderRadius: "8px",

    "&:hover": {
      background: "#D9D9D9",
      color: `${variables.preplixPrimary}`,
    },
  },
  btnSecondary: {
    color: "#232326",
    background: "#D9D9D9",
    marginLeft: "2rem",
    marginRight: "2rem",
    marginTop: "10px",
    padding: "10px 12px",
    minWidth: "173px",
    borderRadius: "8px",

    "&:hover": {
      background: `${variables.preplixPrimary}`,
      color: "white",
    },
  },
  comment_box: {
    border: "1px solid #B6B6B7",
    borderRadius: "15px",
    padding: "20px 30px",
  },
  modal_close: { position: "absolute", top: 5, right: 5, zIndex: 1 },
  add_button: {
    borderRadius: "5rem",
    backgroundColor: variables.preplixPrimary,
    paddingX: 4,
    "&:hover": {
      backgroundColor: variables.preplixPrimary,
    },
  },
  comment_input: {
    backgroundColor: "#FFFFFF",
    marginBottom: 0,
    borderRadius: "30px",
    "& .MuiInputBase-root": {
      border: "1px solid #FFA7B7",
      borderRadius: "30px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  table_column: (width: number) => ({
    minWidth: width,
    background: "#EBEBEB",
    borderBottom: "1px solid #9D9D9D",
    fontSize: "18px",
  }),
  table_row: { boxShadow: "0px 1px 4px 0px #00000040" },
  checkbox: {
    color: variables.preplixPrimary,
    "&.Mui-checked": {
      color: variables.preplixPrimary,
    },
  },
  pagination_container: {
    display: "flex",
    justifyContent: "center",
  },
  pagination_box: {
    width: "fit-content",
    background: "#FDF3F4",
  },
};
