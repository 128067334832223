import React, { FC } from "react";
import {
  Navigate,
  Route,
  Routes as Switch,
  useLocation,
} from "react-router-dom";
import { AlertColor, Grid, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { routes } from "../utils/constants/routes";
import {
  Authenticated,
  NotificationDisplay,
  NotificationMessage,
  NotificationSeverity,
  setNotificationMessage,
} from "../utils/redux";
import Notification from "../components/Notification";
import Login from "../screens/Authentication/Login";
import Dashboard from "../screens/Authenticated/Dashboard";
import Applications from "../screens/Authenticated/Applications";
import Listing from "../screens/Authenticated/Listing";
import StudentListing from "../screens/Authenticated/StudentListing";
import TeacherListing from "../screens/Authenticated/TeacherListing";
import UserManagement from "../screens/Authenticated/UserManagement";

import AuthenticationLayout from "./AuthenticationLayout";
import PrivateRoute from "./PrivateRoute";
import RoleManagement from "../screens/Authenticated/RoleManagement/RoleManagement";
import Profile from "../screens/Authenticated/Profile";

const Root: FC = () => {
  let location = useLocation();
  const isAuthenticated = useSelector(Authenticated);

  let { from } = location.state || {
    from: {
      pathname: routes.dashboard,
    },
  };

  return isAuthenticated ? (
    <Navigate to={from} />
  ) : (
    <Navigate to={routes.login} />
  );
};

const Routes: FC = () => {
  const dispatch = useDispatch();

  const notificationDisplay = useSelector(NotificationDisplay);
  const notificationSeverity = useSelector(NotificationSeverity);
  const notificationMessage = useSelector(NotificationMessage);

  return (
    <Grid container flexDirection="column">
      <Grid className="pageWrapper" item sx={{ width: "100%" }}>
        <Paper elevation={0} square={true} className="paperWrapper">
          <Notification
            isOpen={notificationDisplay}
            message={notificationMessage}
            severity={notificationSeverity as AlertColor}
            autoHideDuration={6000}
            closeNotification={(value) =>
              dispatch(
                setNotificationMessage({
                  display: value,
                  severity: notificationSeverity,
                  message: notificationMessage,
                }),
              )
            }
          />
          <Switch>
            <Route path={routes.root} element={<Root />} />
            <Route
              path={routes.login}
              element={<AuthenticationLayout Component={Login} />}
            />
            <Route
              path={routes.dashboard}
              element={<PrivateRoute Component={Dashboard} />}
            />
            <Route
              path={routes.listing}
              element={<PrivateRoute Component={Listing} />}
            />
            <Route
              path={routes.applications}
              element={<PrivateRoute Component={Applications} />}
            />
            <Route
              path={routes.student_listing}
              element={<PrivateRoute Component={StudentListing} />}
            />
            <Route
              path={routes.teacher_listing}
              element={<PrivateRoute Component={TeacherListing} />}
            />
            <Route
              path={routes.user_management}
              element={<PrivateRoute Component={UserManagement} />}
            />
            <Route
              path={routes.role_management}
              element={<PrivateRoute Component={RoleManagement} />}
            />
            <Route
              path={routes.profile}
              element={<PrivateRoute Component={Profile} />}
            />
          </Switch>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Routes;
