export const routes = {
  root: "/",
  login: "/login",
  forgot_password: "/forgot-password",
  dashboard: "/dashboard",
  applications: "/applications",
  listing: "/listings",
  student_listing: "/listing/students",
  teacher_listing: "/listing/teachers",
  user_management: "/listing/users",
  franchise: "/listing/franchise",
  role_management: "/roles",
  profile: "/manage-profile",
};
