export const URLS = {
  login: "/profile/api/token",
  user: "/profile/authenticated/users",
  profile: "/profile/authenticated/users-profile",
  view_listing: (role: string) => `/teacher/authenticated/${role}/courses/page`,
  dashboard_counters: (role: string) =>
    `/teacher/authenticated/${role}/counters`,
  course: (role: string) => `/teacher/authenticated/${role}/courses`,
  course_list: "/teacher/api/course-names",
  applications: (role: string, suffix: string) =>
    `/teacher/authenticated/${role}/applications${suffix}`,
  list: (role: string) => `/profile/authenticated/users${role}/page`,
  roles_list: "/profile/authenticated/roles/page",
  roles_list_dropdown: "/profile/authenticated/roles",
  roles: "profile/authenticated/roles",
  parent_users: (id: string) =>
    `profile/authenticated/users/roles/${id}/parent`,
  crm_users_dropdown: (role: string) =>
    `/profile/authenticated/crm-representatives?role=${role}`,
  crm_status_dropdown: (role: string, title: string | null) =>
    `/profile/authenticated/crm-statuses?role=${role}${!!title ? "&listingType=" + title : ""}`,
  assign_representative: (role: string, rep_id: string, user_id: string) =>
    `/profile/authenticated/assign-representatives?representativeId=${rep_id}&userId=${user_id}&role=${role}`,
  assign_status: (role: string, status_id: string, user_id: string) =>
    `/profile/authenticated/assign-status?statusId=${status_id}&userId=${user_id}&role=${role}`,
  representative_remarks: (role: string, user_id: string) =>
    `/profile/authenticated/representative-remarks?userId=${user_id}&role=${role}`,
  update_franchise: (id: string) => `/profile/authenticated/franchises/${id}`,
  get_franchise_profile: (id: string) =>
    `/profile/authenticated/franchises/${id}/profile`,
};
