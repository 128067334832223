import React, {
  FC,
  useState,
  CSSProperties,
  useEffect,
  useCallback,
  ChangeEvent,
} from "react";
import {
  Container,
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  AlertColor,
  Stack,
  Pagination,
  IconButton,
  Button,
  Divider,
  Modal,
  Box,
} from "@mui/material";
import { logout } from "../../../utils/redux";
import axiosInstance from "../../../utils/axios";
import { URLS } from "../../../utils/constants/urls";
import Notification from "../../../components/Notification";
import PageLoader from "../../../components/PageLoader";
import { styles } from "../../../utils/style/listing-styles";
import { FormModel } from "../../../components/Form";
import RenderFormFields from "../../../components/Form/helper";
import { AutoCompleteOption } from "../../../components/AutoComplete/AutoComplete";
import { images } from "../../../utils/constants/images";
import { useDispatch } from "react-redux";
import { ApplicationsForm } from "./ApplicationsForm";
import { Close } from "@mui/icons-material";
import { DropDown } from "../../../utils/constants/identifiers";

interface Column {
  id: keyof ApplicationListingData;
  label: string;
  minWidth: number;
  align: "right" | "left";
}

const columns: Column[] = [
  { id: "studentName", label: "Student Name", minWidth: 250, align: "left" },
  {
    id: "country",
    label: "Country",
    minWidth: 200,
    align: "left",
  },
  {
    id: "preferredCourse",
    label: "Preferred Course",
    minWidth: 200,
    align: "left",
  },
  {
    id: "universityPreference",
    label: "University Preference",
    minWidth: 200,
    align: "left",
  },
  {
    id: "annualFeeBudget",
    label: "Annual Fees Budget",
    minWidth: 200,
    align: "left",
  },
  {
    id: "admissionPlan",
    label: "Admission Plan",
    minWidth: 200,
    align: "left",
  },
  {
    id: "ieltsAttempted",
    label: "IELTS Attempted",
    minWidth: 150,
    align: "left",
  },
  {
    id: "ieltsScore",
    label: "IELTS Score",
    minWidth: 150,
    align: "left",
  },
  { id: "status", label: "Status", minWidth: 100, align: "left" },
];

export interface ApplicationListingData {
  id: number;
  country: AutoCompleteOption;
  preferredCourse: AutoCompleteOption;
  universityPreference: AutoCompleteOption;
  annualFeeBudget: number;
  admissionPlan: string;
  ieltsAttempted: boolean;
  ieltsScore: number | null;
  status: string;
  studentName: string;
  teacherName: string;
  studentId: number;
  teacherId: number;
}

const Applications: FC = () => {
  const dispatch = useDispatch();
  const rowsPerPage: number = 10;

  const [applicationForm, setApplicationForm] = useState<any>();
  const [page, setPage] = useState<number>(1);
  const [listing, setListing] = useState<{
    totalElements: number;
    listings: ApplicationListingData[];
    totalPages: number;
  }>({ totalPages: 0, totalElements: 0, listings: [] });
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState({
    display: false,
    severity: "",
    message: "",
  });
  const [admissionReps, setAdmissionReps] = useState<DropDown[]>([]);
  const [admissionStatus, setAdmissionStatus] = useState<DropDown[]>([]);

  const getData = useCallback(async () => {
    try {
      const body = {
        filter: [],
        graphql: null,
        page: page,
        size: rowsPerPage,
        sort: [`id:asc`],
      };

      interface ApiData extends ApplicationListingData {
        applicationCountry: AutoCompleteOption;
        applicationCourse: AutoCompleteOption;
        applicationUniversity: AutoCompleteOption;
      }

      const {
        data,
      }: {
        status: number;
        data: {
          size: number;
          content: ApiData[];
          number: number;
          sort: { sorted: boolean; empty: boolean; unsorted: boolean };
          first: boolean;
          last: true;
          totalPages: number;
          totalElements: number;
          numberOfElements: number;
          numberOfElementsInDatabase: number;
        };
      } = await axiosInstance.post(URLS.applications("admin", "/page"), body);
      const listings: ApplicationListingData[] = [];
      data.content.forEach((listing) => {
        listings.push({
          id: listing.id,
          country: listing.applicationCountry,
          preferredCourse: listing.applicationCourse,
          universityPreference: listing.applicationUniversity,
          annualFeeBudget: listing.annualFeeBudget,
          admissionPlan: listing.admissionPlan,
          ieltsAttempted: listing.ieltsAttempted,
          ieltsScore: listing.ieltsScore,
          studentName: listing.studentName,
          teacherName: listing.teacherName,
          studentId: listing.studentId,
          teacherId: listing.teacherId,
          status: !!listing.status ? listing.status.toLowerCase() : "Pending",
        });
      });
      setListing({
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        listings,
      });
    } catch (e: any) {
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
      console.log(e);
    }
  }, [dispatch, page]);

  const getAdmissionReps = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(
        URLS.crm_users_dropdown("ADMISSIONS"),
      );
      setAdmissionReps(
        data.map((user: { name: string; id: number }) => ({
          label: user.name,
          value: user.id,
        })),
      );
      setLoading(false);
    } catch (e: any) {
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
      setLoading(false);
      console.log(e);
    }
  }, [dispatch]);

  const getAdmissionStatus = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(
        URLS.crm_status_dropdown("ADMISSIONS", "STUDENT"),
      );
      setAdmissionStatus(
        data.map((status: { name: string; id: number }) => ({
          label: status.name,
          value: status.id,
        })),
      );
      setLoading(false);
    } catch (e: any) {
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
      setLoading(false);
      console.log(e);
    }
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      await getData();
      await getAdmissionReps();
      await getAdmissionStatus();
    })();
  }, [getData, getAdmissionReps, getAdmissionStatus]);

  const closeNotification = (value: boolean) => {
    setMessage((prevState) => ({ ...prevState, display: value }));
  };

  const handleChangePage = async (
    event: ChangeEvent<unknown>,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const editApplication = async (id: number) => {
    try {
      setLoading(true);
      const { status, data } = await axiosInstance.get(
        URLS.applications("students", `/${id}`),
      );
      if (status === 200) {
        setLoading(false);
        setApplicationForm({
          admissionPlan: data.admissionPlan,
          ieltsScore: data.ieltsScore,
          studentId: data.student.id,
          studentName: data.student.name,
          teacherId: data.teacher.id,
          teacherName: data.teacher.name,
          annualFeeBudget: parseInt(data.annualFeeBudget),
          ieltsAttempted: data.ieltsAttempted ? "Yes" : "No",
          country: data.country.id,
          preferredCourse: data.preferredCourse.id,
          universityPreference: data.universityPreference.id,
          country_name: data.country.name,
          preferred_course_name: data.preferredCourse.name,
          university_preference_name: data.universityPreference.name,
          status: data.status.value,
        });
        setOpen(true);
      }
    } catch (e: any) {
      console.log(e);
      if (e.response) {
        setMessage({
          display: true,
          severity: "error",
          message: e.response.data.message,
        });
      } else {
        setMessage({
          display: true,
          severity: "error",
          message: "Something went wrong. Please try again after sometime",
        });
      }
      setLoading(false);
    }
  };

  const updateApplication = async () => {
    try {
      setLoading(true);
      const { status } = await axiosInstance.post(
        URLS.applications("admin", ""),
        applicationForm,
      );
      if (status === 201) {
        setLoading(false);
        setMessage({
          display: true,
          severity: "success",
          message: "Course Added Successfully",
        });
        await closeModal(true);
      }
    } catch (e: any) {
      console.log(e);
      if (e.response) {
        setMessage({
          display: true,
          severity: "error",
          message: e.response.data.message,
        });
      } else {
        setMessage({
          display: true,
          severity: "error",
          message: "Something went wrong. Please try again after sometime",
        });
      }
      setLoading(false);
    }
  };

  const closeModal = async (flag?: boolean) => {
    if (!!flag) {
      await getData();
    }
    setOpen(false); // Close the modal
  };

  return (
    <Container
      maxWidth={false}
      disableGutters={true}
      sx={{
        backgroundColor: "#DEEDF7",
        padding: "20px",
        height: "auto",
      }}
    >
      {loading && <PageLoader />}
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}
      <Typography
        variant="h6"
        component="h6"
        p={3}
        textTransform={"capitalize"}
      >
        Application Listing
      </Typography>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          maxWidth: "calc(100vw - 105px)",
        }}
      >
        <TableContainer
          sx={{ maxHeight: "calc(100vh - 290px)", overflow: "auto" }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={"center"}
                    style={{
                      minWidth: column.minWidth,
                      background: "#EBEBEB",
                      borderBottom: "1px solid #9D9D9D",
                      fontSize: "18px",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell
                  align={"center"}
                  style={{
                    minWidth: 200,
                    background: "#EBEBEB",
                    borderBottom: "1px solid #9D9D9D",
                    fontSize: "18px",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listing.listings.map((row) => (
                <TableRow
                  style={{ boxShadow: "0px 1px 4px 0px #00000040" }}
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                >
                  {columns.map((column, index) => {
                    const value =
                      column.id !== "id"
                        ? column.id !== "ieltsAttempted"
                          ? column.id === "country" ||
                            column.id === "preferredCourse" ||
                            column.id === "universityPreference"
                            ? row[
                                column.id as
                                  | "country"
                                  | "preferredCourse"
                                  | "universityPreference"
                              ].name
                            : row[column.id as keyof ApplicationListingData]
                          : row[column.id as keyof ApplicationListingData]
                            ? "Yes"
                            : "No"
                        : `${index + 1}`;
                    let textStyle: CSSProperties = {
                      fontSize: "16px",
                      fontWeight: "600",
                    };

                    if (column.id === "status") {
                      if (value === "pending") {
                        textStyle = {
                          ...textStyle,
                          textTransform: "capitalize",
                          color: "#FF9924",
                          backgroundColor: "#FF992426",
                          borderRadius: "10px",
                          padding: "10px",
                        };
                      } else if (value === "approved") {
                        textStyle = {
                          ...textStyle,
                          textTransform: "capitalize",
                          color: "#126616",
                          backgroundColor: "#12661626",
                          borderRadius: "10px",
                          padding: "10px",
                        };
                      }
                    }
                    return (
                      <TableCell
                        key={column.id}
                        align="center"
                        sx={{ padding: "25px" }}
                      >
                        <Typography sx={textStyle}>
                          {value as unknown as string}
                        </Typography>
                      </TableCell>
                    );
                  })}
                  {/*Admission Rep Dropdown*/}
                  {/*<TableCell align="center" sx={{ padding: "25px" }}>*/}
                  {/*  <DropDownInput*/}
                  {/*      disabled={loading}*/}
                  {/*      options={admissionReps}*/}
                  {/*      textChange={(value) =>*/}
                  {/*          setRepForUser("ADMISSIONS", value, row.id as string)*/}
                  {/*      }*/}
                  {/*      inputValue={*/}
                  {/*        !!row.operationsUserId*/}
                  {/*            ? row.operationsUserId.toString()*/}
                  {/*            : ""*/}
                  {/*      }*/}
                  {/*  />*/}
                  {/*</TableCell>*/}
                  {/*/!*Ops Call Status*!/*/}
                  {/*<TableCell align="center" sx={{ padding: "25px" }}>*/}
                  {/*  <DropDownInput*/}
                  {/*      disabled={loading}*/}
                  {/*      options={admissionStatus}*/}
                  {/*      textChange={(value) =>*/}
                  {/*          setStatusForUser(*/}
                  {/*              "ADMISSIONS",*/}
                  {/*              value,*/}
                  {/*              row.id as string,*/}
                  {/*          )*/}
                  {/*      }*/}
                  {/*      inputValue={row.salesUserId as string}*/}
                  {/*  />*/}
                  {/*</TableCell>*/}
                  {/*/!*Admission Comments*!/*/}
                  {/*<TableCell align="center" sx={{ padding: "25px" }}>*/}
                  {/*  <PrimaryButton*/}
                  {/*      fullWidth={false}*/}
                  {/*      color={"secondary"}*/}
                  {/*      sx={{*/}
                  {/*        // mt: 1,*/}
                  {/*        borderRadius: "24px 0px 24px 0px",*/}
                  {/*        textTransform: "capitalize",*/}
                  {/*      }}*/}
                  {/*      onClick={() =>*/}
                  {/*          openCommentModal(row.id as string, "ADMISSIONS")*/}
                  {/*      }*/}
                  {/*  >*/}
                  {/*    View*/}
                  {/*  </PrimaryButton>*/}
                  {/*</TableCell>*/}
                  <TableCell align={"center"} sx={{ padding: "25px" }}>
                    {/* Edit button */}
                    <IconButton
                      key="edit"
                      color="default"
                      aria-label="edit"
                      onClick={() => editApplication(row.id)}
                    >
                      <img
                        src={images.edit}
                        alt="edit"
                        className={"logo-icon-dashboard"}
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container sx={[{ padding: "15px 0" }]}>
          <Grid
            item
            lg={12}
            sx={[
              {
                display: "flex",
                justifyContent: "center",
              },
            ]}
          >
            <div
              style={{
                width: "fitContent",
                background: "#FDF3F4",
                borderRadius: "24px",
                padding: "20px",
              }}
            >
              <Stack spacing={2}>
                <Pagination
                  sx={styles.pagination}
                  count={listing.totalPages}
                  defaultPage={1}
                  shape="rounded"
                  onChange={handleChangePage}
                />
              </Stack>
            </div>
          </Grid>
        </Grid>
      </Paper>
      <Modal open={open} onClose={() => closeModal()}>
        <Box sx={styles.modalContent}>
          <IconButton
            onClick={() => closeModal()} // Close the modal when IconButton is clicked
            sx={{ position: "absolute", top: 5, right: 5, zIndex: 1 }}
          >
            <Close />
          </IconButton>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Application
          </Typography>
          <Divider />
          <Container>
            <Grid container>
              {ApplicationsForm()
                .map((item: FormModel) => ({
                  ...item,
                  value: !!applicationForm
                    ? (applicationForm[item.field] as string | boolean)
                    : "",
                }))
                .map((item: FormModel, index: number) => (
                  <Grid item key={index} xs={6} padding={2}>
                    <Typography
                      fontWeight={600}
                      fontSize={"18px"}
                      color="#545454"
                    >
                      {item.label}
                    </Typography>
                    <RenderFormFields
                      value={item.value as string}
                      item={item}
                      handleChange={(value: string, field: string) => {
                        const data = {
                          [field]: value,
                        };
                        setApplicationForm((prevState: any) => ({
                          ...prevState,
                          ...data,
                        }));
                      }}
                    />
                  </Grid>
                ))}
            </Grid>
            <Grid container marginTop={4}>
              <Grid item xs={12} textAlign={"end"}>
                <Button
                  variant="contained"
                  onClick={updateApplication}
                  sx={{
                    borderRadius: "5rem",
                    backgroundColor: "#D10724",
                    paddingX: 4,
                    "&:hover": {
                      backgroundColor: "#D10724",
                    },
                  }}
                >
                  Update Application
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Modal>
    </Container>
  );
};

export default Applications;
