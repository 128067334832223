export const identifiers: { [key: string]: string | number } = {
  access_token: "access_token",
  refresh_token: "refresh_token",
  date_format: "DD/MM/YYYY",
  franchise_id: "franchise_id",
};

export interface DropDown {
  label: string;
  value: string;
}
