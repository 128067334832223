import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
} from "react";
import {
  Container,
  Grid,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  AlertColor,
  Stack,
  Pagination,
  Modal,
  Checkbox,
  Box,
  Divider,
  Button,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { logout } from "../../../utils/redux";
import axiosInstance from "../../../utils/axios";
import { URLS } from "../../../utils/constants/urls";
import Notification from "../../../components/Notification/Notification";
import PageLoader from "../../../components/PageLoader/index";
import { styles } from "./styles";
import PrimaryButton from "../../../components/PrimaryButton";
import { Close } from "@mui/icons-material";
import { FormModel } from "../../../components/Form";
import RenderFormFields from "../../../components/Form/helper";
import { UserForm } from "./UserForm";
import { DropDown, identifiers } from "../../../utils/constants/identifiers";
import DropDownInput from "../../../components/DropDownInput";
import InputField from "../../../components/InputField";
import { Validators } from "../../../utils/validators";
import dayjs from "dayjs";

interface Column {
  id: keyof UserPrivileges;
  label: string;
  minWidth: number;
  align: "right" | "left";
}

interface PrivilegeColumn {
  id: string;
  label: string;
  minWidth: number;
  align: "right" | "left";
}

export interface UserData {
  sno?: number;
  id?: string;
  name?: string;
  email?: string;
  phone?: string;
  city?: string;
  country?: string;
  designation?: string;
}

export interface UserPrivileges extends UserData {
  selectedPrivileges?: { id: number; name: string; selected: boolean }[];
}

interface UserListingProps {
  columns: Column[];
  title: string;
}

interface UserComment {
  id: number;
  remark: string;
  remarkAddedAt: number | Date;
  remarkAddedBy: string;
}

interface UserComments {
  role: string;
  userId: string;
  comment: string;
  comments: UserComment[];
}

const UserListing: FC<UserListingProps> = ({ columns, title }) => {
  const dispatch = useDispatch();
  const rowsPerPage: number = 10;

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openCommentsModal, setOpenCommentsModal] = useState<boolean>(false);
  const [listing, setListing] = useState<{
    totalElements: number;
    listings: { [key: string]: string | boolean | DropDown[] }[];
    totalPages: number;
  }>({ totalPages: 0, totalElements: 0, listings: [] });
  const [message, setMessage] = useState({
    display: false,
    severity: "",
    message: "",
  });
  const [salesReps, setSalesReps] = useState<DropDown[]>([]);
  const [opsReps, setOpsReps] = useState<DropDown[]>([]);
  const [admissionReps, setAdmissionReps] = useState<DropDown[]>([]);
  const [salesStatus, setSalesStatus] = useState<DropDown[]>([]);
  const [opsStatus, setOpsStatus] = useState<DropDown[]>([]);
  const [admissionStatus, setAdmissionStatus] = useState<DropDown[]>([]);
  const [roles, setRoles] = useState<DropDown[]>([]);
  const [privilegeColumns, setPrivilegeColumns] = useState<PrivilegeColumn[]>(
    [],
  );
  const [userComments, setUserComments] = useState<UserComments>({
    comment: "",
    comments: [],
    role: "",
    userId: "",
  });
  const [userForm, setUserForm] = useState<any>();

  const getData = useCallback(async () => {
    try {
      setLoading(true);
      const body = {
        filter: [],
        graphql: null,
        page: page,
        size: rowsPerPage,
        sort: [`id:asc`],
      };

      interface ApiData extends UserPrivileges {
        firstName: string;
        lastName: string;
        roles: { id: number; name: string }[];
        salesUserId: string;
        operationsUserId: string;
        admissionsUserId: string;
        salesStatusId: string;
        operationsStatusId: string;
        admissionsStatusId: string;
        operationsUser: { id: number; fullName: string };
        admissionsUser: { id: number; fullName: string };
        salesUser: { id: number; fullName: string };
        reportingTo: string;
      }

      const {
        data,
      }: {
        status: number;
        data: {
          size: number;
          content: ApiData[];
          number: number;
          sort: { sorted: boolean; empty: boolean; unsorted: boolean };
          first: boolean;
          last: true;
          totalPages: number;
          totalElements: number;
          numberOfElements: number;
        };
      } = await axiosInstance.post(
        URLS.list(`${title === "staff" ? "" : "/" + title.toUpperCase()}`),
        body,
      );

      const listings: { [key: string]: string | DropDown[] }[] = [];
      data.content.forEach((listing) => {
        const privileges: { [key: string]: boolean } = {};
        if (!!listing.selectedPrivileges) {
          listing.selectedPrivileges.forEach((privilege) => {
            privileges[privilege.name] = privilege.selected;
          });
        }
        listings.push({
          id: listing.id as string,
          name:
            (listing.firstName ? listing.firstName + " " : "") +
            (listing.lastName ? listing.lastName : ""),
          email: listing.email as string,
          phone: listing.phone as string,
          designation: listing.roles
            .map((role) => role.name.split("_").join(" "))
            .join(", "),
          city: listing.city as string,
          country: listing.country as string,
          salesUserId: listing.salesUserId,
          operationsUserId: listing.operationsUserId,
          admissionsUserId: listing.admissionsUserId,
          salesStatusId: listing.salesStatusId,
          operationsStatusId: listing.operationsStatusId,
          admissionsStatusId: listing.admissionsStatusId,
          salesUser: !!listing.salesUser
            ? [
                {
                  label: listing.salesUser.fullName,
                  value: listing.salesUser.id.toString(),
                },
              ]
            : [],
          operationsUser: !!listing.operationsUser
            ? [
                {
                  label: listing.operationsUser.fullName,
                  value: listing.operationsUser.id.toString(),
                },
              ]
            : [],
          admissionsUser: !!listing.admissionsUser
            ? [
                {
                  label: listing.admissionsUser.fullName,
                  value: listing.admissionsUser.id.toString(),
                },
              ]
            : [],
          reportingTo: listing.reportingTo,
          ...privileges,
        });
      });
      setListing({
        totalPages: data.totalPages,
        totalElements: data.totalElements,
        listings,
      });
      if (
        title === "staff" &&
        data.content.length > 0 &&
        !!data.content[0].selectedPrivileges
      ) {
        const privilege_columns: PrivilegeColumn[] =
          data.content[0].selectedPrivileges.map((privilege) => ({
            id: privilege.name,
            label: privilege.name,
            minWidth: 150,
            align: "left",
          }));
        setPrivilegeColumns(privilege_columns);
      }
      setLoading(false);
    } catch (e: any) {
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
      setLoading(false);
      console.log(e);
    }
  }, [dispatch, page, title]);

  const getRoles = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(URLS.roles_list_dropdown);
      setRoles(
        data.map((role: { name: string; id: number }) => ({
          label: role.name,
          value: role.id,
        })),
      );
      setLoading(false);
    } catch (e: any) {
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
      setLoading(false);
      console.log(e);
    }
  }, [dispatch]);

  const getSalesReps = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(
        URLS.crm_users_dropdown("SALES"),
      );
      setSalesReps(
        data.map((user: { name: string; id: number }) => ({
          label: user.name,
          value: user.id,
        })),
      );
      setLoading(false);
    } catch (e: any) {
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
      setLoading(false);
      console.log(e);
    }
  }, [dispatch]);

  const getOpsReps = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(
        URLS.crm_users_dropdown("OPERATIONS"),
      );
      setOpsReps(
        data.map((user: { name: string; id: number }) => ({
          label: user.name,
          value: user.id,
        })),
      );
      setLoading(false);
    } catch (e: any) {
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
      setLoading(false);
      console.log(e);
    }
  }, [dispatch]);

  const getAdmissionReps = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(
        URLS.crm_users_dropdown("ADMISSIONS"),
      );
      setAdmissionReps(
        data.map((user: { name: string; id: number }) => ({
          label: user.name,
          value: user.id,
        })),
      );
      setLoading(false);
    } catch (e: any) {
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
      setLoading(false);
      console.log(e);
    }
  }, [dispatch]);

  const getSalesStatus = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(
        URLS.crm_status_dropdown("SALES", title.toUpperCase()),
      );
      setSalesStatus(
        data.map((status: { name: string; id: number }) => ({
          label: status.name,
          value: status.id,
        })),
      );
      setLoading(false);
    } catch (e: any) {
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
      setLoading(false);
      console.log(e);
    }
  }, [dispatch, title]);

  const getOpsStatus = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(
        URLS.crm_status_dropdown("OPERATIONS", title.toUpperCase()),
      );
      setOpsStatus(
        data.map((status: { name: string; id: number }) => ({
          label: status.name,
          value: status.id,
        })),
      );
      setLoading(false);
    } catch (e: any) {
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
      setLoading(false);
      console.log(e);
    }
  }, [dispatch, title]);

  const getAdmissionStatus = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(
        URLS.crm_status_dropdown("ADMISSIONS", title.toUpperCase()),
      );
      setAdmissionStatus(
        data.map((status: { name: string; id: number }) => ({
          label: status.name,
          value: status.id,
        })),
      );
      setLoading(false);
    } catch (e: any) {
      if (e.response) {
        if (e.response.status === 401) {
          dispatch(logout());
        } else {
          setMessage({
            display: true,
            severity: "error",
            message: e.response.data.message,
          });
        }
      }
      setLoading(false);
      console.log(e);
    }
  }, [dispatch, title]);

  useEffect(() => {
    (async () => {
      await getData();
      if (title === "staff") {
        await getRoles();
      }
      if (title !== "staff") {
        await getSalesReps();
        await getSalesStatus();
      }
      if (title !== "staff") {
        await getOpsReps();
        await getOpsStatus();
      }
      if (title === "student") {
        await getAdmissionReps();
        await getAdmissionStatus();
      }
    })();
  }, [
    getData,
    getRoles,
    title,
    getSalesReps,
    getOpsReps,
    getSalesStatus,
    getOpsStatus,
    getAdmissionReps,
    getAdmissionStatus,
  ]);

  const handleCloseModal = async (flag?: boolean) => {
    if (!!flag) {
      await getData();
    }
    setOpenModal(false); // Close the modal
  };

  const closeNotification = (value: boolean) => {
    setMessage((prevState) => ({ ...prevState, display: value }));
  };

  const openAddUser = () => {
    setUserForm({});
    setOpenModal(true);
  };

  const handleChangePage = async (
    event: ChangeEvent<unknown>,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const addUser = async () => {
    try {
      setLoading(true);
      await axiosInstance.post(URLS.user, {
        ...userForm,
        roles: [
          title === "teacher"
            ? 1
            : title === "student"
              ? 2
              : parseInt(userForm.role),
        ],
      });
      setOpenModal(false);
      setLoading(false);
      await getData();
    } catch (e: any) {
      console.log(e);
      if (e.response) {
        setMessage({
          display: true,
          severity: "error",
          message: e.response.data.message,
        });
      } else {
        setMessage({
          display: true,
          severity: "error",
          message: "Something went wrong. Please try again after sometime",
        });
      }
      setLoading(false);
    }
  };

  const addFormValid = () => {
    return (
      !!userForm &&
      !!userForm.firstName &&
      !!userForm.lastName &&
      !!userForm.email &&
      !!userForm.phoneNumber
    );
  };

  const getParentUsers = async (id: string) => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(URLS.parent_users(id));
      console.log(data);
      setLoading(false);
    } catch (e: any) {
      console.log(e);
      if (e.response) {
        setMessage({
          display: true,
          severity: "error",
          message: e.response.data.message,
        });
      } else {
        setMessage({
          display: true,
          severity: "error",
          message: "Something went wrong. Please try again after sometime",
        });
      }
      setLoading(false);
    }
  };

  const setRepForUser = async (role: string, value: string, id: string) => {
    try {
      setLoading(true);
      const { status } = await axiosInstance.put(
        URLS.assign_representative(role, value, id),
        {},
      );
      console.log(status);
      await getData();
      setLoading(false);
    } catch (e: any) {
      console.log(e);
      if (e.response) {
        setMessage({
          display: true,
          severity: "error",
          message: e.response.data.message,
        });
      } else {
        setMessage({
          display: true,
          severity: "error",
          message: "Something went wrong. Please try again after sometime",
        });
      }
      setLoading(false);
    }
  };

  const setStatusForUser = async (role: string, value: string, id: string) => {
    try {
      setLoading(true);
      const { status } = await axiosInstance.put(
        URLS.assign_status(role, value, id),
        {},
      );
      console.log(status);
      await getData();
      setLoading(false);
    } catch (e: any) {
      console.log(e);
      if (e.response) {
        setMessage({
          display: true,
          severity: "error",
          message: e.response.data.message,
        });
      } else {
        setMessage({
          display: true,
          severity: "error",
          message: "Something went wrong. Please try again after sometime",
        });
      }
      setLoading(false);
    }
  };

  const getUserComments = async (userId: string, role: string) => {
    try {
      const { data } = await axiosInstance.get(
        URLS.representative_remarks(role, userId),
      );
      return data;
    } catch (e: any) {
      throw e;
    }
  };

  const openCommentModal = async (userId: string, role: string) => {
    try {
      setLoading(true);
      const data = await getUserComments(userId, role);
      setUserComments({
        comment: "",
        userId,
        role,
        comments: data,
      });
      setOpenCommentsModal(true);
      setLoading(false);
    } catch (e: any) {
      console.log(e);
      if (e.response) {
        setMessage({
          display: true,
          severity: "error",
          message: e.response.data.message,
        });
      } else {
        setMessage({
          display: true,
          severity: "error",
          message: "Something went wrong. Please try again after sometime",
        });
      }
      setLoading(false);
    }
  };

  const addComment = async () => {
    try {
      setLoading(true);
      await axiosInstance.put(
        URLS.representative_remarks(userComments.role, userComments.userId),
        { remark: userComments.comment },
      );
      const data = await getUserComments(
        userComments.userId,
        userComments.role,
      );
      setMessage({
        display: true,
        severity: "success",
        message: "Comment Added Successfully",
      });
      setUserComments((prevState) => ({
        ...prevState,
        comment: "",
        comments: data,
      }));
      // setOpenCommentsModal(false);
      setLoading(false);
    } catch (e: any) {
      console.log(e);
      if (e.response) {
        setMessage({
          display: true,
          severity: "error",
          message: e.response.data.message,
        });
      } else {
        setMessage({
          display: true,
          severity: "error",
          message: "Something went wrong. Please try again after sometime",
        });
      }
      setLoading(false);
    }
  };

  return (
    <Container maxWidth={false} disableGutters={true} sx={styles.root}>
      {loading && <PageLoader />}
      {message.display && (
        <Notification
          isOpen={message.display}
          message={message.message}
          severity={message.severity as AlertColor}
          closeNotification={closeNotification}
        />
      )}
      <Grid container mt={{ xs: 7, lg: 0 }}>
        <Grid item lg={6}>
          <Typography
            variant={"h6"}
            component={"h6"}
            p={3}
            textTransform={"capitalize"}
          >
            {title} Listing
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6} textAlign={"right"} alignContent={"center"}>
          <PrimaryButton
            fullWidth={false}
            color={"secondary"}
            sx={styles.add_user_button}
            onClick={openAddUser}
          >
            Add {title}
          </PrimaryButton>
        </Grid>
      </Grid>

      <Paper sx={styles.table_section}>
        <TableContainer sx={styles.table_container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={"center"}
                    sx={styles.table_column(column.minWidth)}
                  >
                    {column.label}
                  </TableCell>
                ))}
                {privilegeColumns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={"center"}
                    sx={styles.table_column(column.minWidth)}
                  >
                    {column.label}
                  </TableCell>
                ))}
                {title !== "staff" && (
                  <>
                    <TableCell align={"center"} sx={styles.table_column(200)}>
                      Sales Representative
                    </TableCell>
                    <TableCell align={"center"} sx={styles.table_column(150)}>
                      Sales Status
                    </TableCell>
                    <TableCell align={"center"} sx={styles.table_column(150)}>
                      Comments
                    </TableCell>
                    <TableCell align={"center"} sx={styles.table_column(200)}>
                      Operations Representative
                    </TableCell>
                    <TableCell align={"center"} sx={styles.table_column(150)}>
                      Operations Status
                    </TableCell>
                    <TableCell align={"center"} sx={styles.table_column(150)}>
                      Comments
                    </TableCell>
                  </>
                )}
                {title === "student" && (
                  <>
                    <TableCell align={"center"} sx={styles.table_column(200)}>
                      Admission Representative
                    </TableCell>
                    <TableCell align={"center"} sx={styles.table_column(150)}>
                      Admission Status
                    </TableCell>
                    <TableCell align={"center"} sx={styles.table_column(150)}>
                      Comments
                    </TableCell>
                  </>
                )}
                <TableCell align={"center"} sx={styles.table_column(200)}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listing.listings.map((row, index) => (
                <TableRow
                  sx={styles.table_row}
                  hover
                  role={"checkbox"}
                  tabIndex={-1}
                  key={index}
                >
                  {columns.map((column, index) => {
                    let value;
                    if (column.id === "sno") {
                      value = index + 1;
                    } else if (
                      column.id !== "id" &&
                      column.id !== "selectedPrivileges"
                    ) {
                      value = row[column.id as keyof UserData];
                    } else if (column.id !== "selectedPrivileges") {
                      value = `PRE-${row[column.id as keyof UserData]}`;
                    } else {
                      value = "";
                    }

                    return (
                      <TableCell
                        key={index}
                        align={"center"}
                        sx={{ padding: 3 }}
                      >
                        <Typography fontSize={"16px"} fontWeight={600}>
                          {value as string}
                        </Typography>
                      </TableCell>
                    );
                  })}
                  {privilegeColumns.map((column, index) => (
                    <TableCell key={index} align={"center"} sx={{ padding: 3 }}>
                      <Checkbox
                        checked={row[column.id] as boolean}
                        sx={styles.checkbox}
                      />
                    </TableCell>
                  ))}
                  {title !== "staff" && (
                    <>
                      {/*Sales Rep Dropdown*/}
                      <TableCell align={"center"} sx={{ padding: 3 }}>
                        <DropDownInput
                          disabled={loading}
                          options={
                            salesReps.length > 0
                              ? salesReps
                              : (row.salesUser as DropDown[]) || []
                          }
                          textChange={(value) =>
                            setRepForUser("SALES", value, row.id as string)
                          }
                          inputValue={
                            !!row.salesUserId ? row.salesUserId.toString() : ""
                          }
                        />
                      </TableCell>
                      {/*Sales Call Status*/}
                      <TableCell align={"center"} sx={{ padding: 3 }}>
                        <DropDownInput
                          disabled={loading}
                          options={salesStatus}
                          textChange={(value) =>
                            setStatusForUser("SALES", value, row.id as string)
                          }
                          inputValue={
                            !!row.salesStatusId
                              ? row.salesStatusId.toString()
                              : ""
                          }
                        />
                      </TableCell>
                      {/*Sales Comments*/}
                      <TableCell align={"center"} sx={{ padding: 3 }}>
                        <PrimaryButton
                          fullWidth={false}
                          color={"secondary"}
                          sx={styles.add_user_button}
                          onClick={() =>
                            openCommentModal(row.id as string, "SALES")
                          }
                        >
                          View
                        </PrimaryButton>
                      </TableCell>
                      {/*Ops Rep Dropdown*/}
                      <TableCell align={"center"} sx={{ padding: 3 }}>
                        <DropDownInput
                          disabled={loading}
                          options={
                            opsReps.length > 0
                              ? opsReps
                              : (row.operationsUser as DropDown[]) || []
                          }
                          textChange={(value) =>
                            setRepForUser("OPERATIONS", value, row.id as string)
                          }
                          inputValue={
                            !!row.operationsUserId
                              ? row.operationsUserId.toString()
                              : ""
                          }
                        />
                      </TableCell>
                      {/*Ops Call Status*/}
                      <TableCell align={"center"} sx={{ padding: 3 }}>
                        <DropDownInput
                          disabled={loading}
                          options={opsStatus}
                          textChange={(value) =>
                            setStatusForUser(
                              "OPERATIONS",
                              value,
                              row.id as string,
                            )
                          }
                          inputValue={
                            !!row.operationsStatusId
                              ? row.operationsStatusId.toString()
                              : ""
                          }
                        />
                      </TableCell>
                      {/*Ops Comments*/}
                      <TableCell align={"center"} sx={{ padding: 3 }}>
                        <PrimaryButton
                          fullWidth={false}
                          color={"secondary"}
                          sx={styles.add_user_button}
                          onClick={() =>
                            openCommentModal(row.id as string, "OPERATIONS")
                          }
                        >
                          View
                        </PrimaryButton>
                      </TableCell>
                    </>
                  )}
                  {title === "student" && (
                    <>
                      {/*Admission Rep Dropdown*/}
                      <TableCell align={"center"} sx={{ padding: 3 }}>
                        <DropDownInput
                          disabled={loading}
                          options={
                            admissionReps.length > 0
                              ? admissionReps
                              : (row.admissionsUser as DropDown[]) || []
                          }
                          textChange={(value) =>
                            setRepForUser("ADMISSIONS", value, row.id as string)
                          }
                          inputValue={
                            !!row.operationsUserId
                              ? row.operationsUserId.toString()
                              : ""
                          }
                        />
                      </TableCell>
                      {/*Ops Call Status*/}
                      <TableCell align={"center"} sx={{ padding: 3 }}>
                        <DropDownInput
                          disabled={loading}
                          options={admissionStatus}
                          textChange={(value) =>
                            setStatusForUser(
                              "ADMISSIONS",
                              value,
                              row.id as string,
                            )
                          }
                          inputValue={row.salesUserId as string}
                        />
                      </TableCell>
                      {/*Admission Comments*/}
                      <TableCell align={"center"} sx={{ padding: 3 }}>
                        <PrimaryButton
                          fullWidth={false}
                          color={"secondary"}
                          sx={styles.add_user_button}
                          onClick={() =>
                            openCommentModal(row.id as string, "ADMISSIONS")
                          }
                        >
                          View
                        </PrimaryButton>
                      </TableCell>
                    </>
                  )}
                  <TableCell align={"center"} sx={{ padding: 3 }}>
                    {/*<IconButton*/}
                    {/*  key="delete"*/}
                    {/*  color="default"*/}
                    {/*  aria-label="delete"*/}
                    {/*  onClick={() => handleDelete(row)}*/}
                    {/*>*/}
                    {/*  <img*/}
                    {/*    src={images.trash}*/}
                    {/*    alt="addListing"*/}
                    {/*    className={"logo-icon-dashboard"}*/}
                    {/*  />*/}
                    {/*</IconButton>*/}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container py={2}>
          <Grid item lg={12} sx={styles.pagination_container}>
            <Box padding={3} borderRadius={3} sx={styles.pagination_box}>
              <Stack spacing={2}>
                <Pagination
                  sx={styles.pagination}
                  count={listing.totalPages}
                  defaultPage={1}
                  shape="rounded"
                  onChange={handleChangePage}
                />
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Modal open={openModal} onClose={() => handleCloseModal()}>
        <Box sx={styles.modalContent}>
          <IconButton
            onClick={() => handleCloseModal()} // Close the modal when IconButton is clicked
            sx={styles.modal_close}
          >
            <Close />
          </IconButton>
          <Typography
            variant={"h6"}
            component={"h2"}
            textTransform={"capitalize"}
          >
            Add New {title}
          </Typography>
          <Divider />
          <Container>
            <Grid container>
              {UserForm(title, roles)
                .map((item: FormModel) => ({
                  ...item,
                  value: !!userForm
                    ? (userForm[item.field] as string | boolean)
                    : "",
                }))
                .map((item: FormModel, index: number) => (
                  <Grid item key={index} xs={6} padding={2}>
                    <Typography
                      fontWeight={600}
                      fontSize={"18px"}
                      color={"#545454"}
                    >
                      {item.label}
                    </Typography>
                    <RenderFormFields
                      value={item.value as string}
                      item={item}
                      handleChange={async (value: string, field: string) => {
                        const data = {
                          [field]: value,
                        };
                        setUserForm((prevState: any) => ({
                          ...prevState,
                          ...data,
                        }));
                        if (field === "role") {
                          await getParentUsers(value);
                        }
                      }}
                    />
                  </Grid>
                ))}
            </Grid>
            <Grid container marginTop={4}>
              <Grid item xs={12} textAlign={"end"}>
                <Button
                  disabled={loading && !addFormValid()}
                  variant={"contained"}
                  onClick={addUser}
                  sx={styles.add_button}
                >
                  Add User
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Modal>
      <Modal
        open={openCommentsModal}
        onClose={() => setOpenCommentsModal(false)}
      >
        <Box sx={styles.modalContent}>
          <IconButton
            onClick={() => setOpenCommentsModal(false)} // Close the modal when IconButton is clicked
            sx={styles.modal_close}
          >
            <Close />
          </IconButton>
          <Typography
            variant={"h6"}
            component={"h2"}
            textTransform={"capitalize"}
          >
            {userComments.role.toLowerCase()} Comments
          </Typography>
          <Divider />
          <Container>
            {userComments.comments.map((item: UserComment, index: number) => (
              <Grid container key={index} mt={2} sx={styles.comment_box}>
                <Grid item xs={12}>
                  <Typography
                    fontSize={"18px"}
                    fontWeight={600}
                    color={"#000000"}
                  >
                    {item.remark}
                  </Typography>
                </Grid>
                <Grid item xs={12} mt={1}>
                  <Typography fontSize={"14px"} color={"#545454"}>
                    {"Added By: " +
                      item.remarkAddedBy +
                      " on " +
                      dayjs(new Date(item.remarkAddedAt as number)).format(
                        identifiers.date_format as string,
                      )}
                  </Typography>
                </Grid>
              </Grid>
            ))}
            <Grid container>
              <Grid item xs={12} mt={2}>
                <Typography
                  fontWeight={600}
                  fontSize={"18px"}
                  color={"#545454"}
                >
                  Add Comment
                </Typography>
                <InputField
                  disabled={false}
                  autoFocus={true}
                  variant={"outlined"}
                  size={"small"}
                  inputProps={{
                    sx: {
                      border: "none",
                    },
                  }}
                  hasError={false}
                  field={"comments"}
                  inputValue={userComments.comment}
                  typeValue={"text"}
                  label={""}
                  fieldError={""}
                  validators={[
                    {
                      check: Validators.required,
                      message: "This field is mandatory",
                    },
                  ]}
                  textChange={(value) =>
                    setUserComments((prevState) => ({
                      ...prevState,
                      comment: value,
                    }))
                  }
                  sx={styles.comment_input}
                  rows={5}
                  multiline={true}
                />
              </Grid>
            </Grid>
            <Grid container marginTop={4}>
              <Grid item xs={12} textAlign={"end"}>
                <Button
                  disabled={loading || !userComments.comment}
                  variant={"contained"}
                  onClick={addComment}
                  sx={styles.add_button}
                >
                  Add Comment
                </Button>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Modal>
    </Container>
  );
};
export default UserListing;
